export function getCourseDurationDisplay(durationSeconds: number): string {
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);

  let result = '';
  if (hours > 0) result += `${hours} hrs `;
  if (minutes > 0) result += `${minutes} mins`;
  if (result === '') result = '0 mins'; // In case duration is less than a minute

  return result.trim();
}
